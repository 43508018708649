var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pt-56 h-100dvh over-flow-y-scroll",
      attrs: { id: "profile" }
    },
    [
      _c(
        "div",
        {
          staticClass: "p-sticky bg z-index-1",
          class: [{ "px-4": _vm.layout !== "template1" }],
          style: { top: "0px" }
        },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                height: "40",
                "background-color": "transparent",
                color: _vm.theme === "dark" ? "secondary" : "primary",
                grow: _vm.layout === "template1"
              },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            _vm._l(_vm.tabs, function(tab, index) {
              return _c(
                "v-tab",
                { key: index, staticClass: "subtitle-1 font-weight-medium" },
                [_vm._v(" " + _vm._s(tab) + " ")]
              )
            }),
            1
          ),
          _c("v-divider", { staticClass: "divider" })
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "px-1" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [_c(_vm.activeContent, { tag: "component" })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }