var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { class: _vm.currentDevice === "ios" ? "pb-72" : "pb-56" },
        [
          _c(
            "Dialog",
            { attrs: { dialog: _vm.dialog.status, width: 320, height: 302 } },
            [
              _c(
                "span",
                {
                  staticClass: "text-h6",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" " + _vm._s(_vm.$t("myCenter.myAvatar")) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column align-center",
                  attrs: { slot: "text" },
                  slot: "text"
                },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "mt-2",
                      style: _vm.avatarBorderClr,
                      attrs: { size: "83", color: "primary" }
                    },
                    [
                      _vm.userBaseInfo.avatar || _vm.dialog.form.avatar
                        ? _c("img", {
                            attrs: {
                              src:
                                _vm.baseImgUrl + _vm.dialog.form.avatar ||
                                _vm.baseImgUrl + _vm.userBaseInfo.avatar,
                              alt: "userBaseInfo.username"
                            }
                          })
                        : _vm._e()
                    ]
                  ),
                  _c("span", { staticClass: "caption title--text mt-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("myCenter.avatarPreview")) + " ")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between w-100 mt-1 px-6"
                    },
                    _vm._l(_vm.dialog.avatarList, function(item) {
                      return _c(
                        "v-avatar",
                        {
                          key: item,
                          staticClass: "mt-2",
                          style:
                            _vm.dialog.form.avatar === item
                              ? _vm.avatarBorderClr
                              : "",
                          attrs: { size: "48", color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.dialog.form.avatar = item
                            }
                          }
                        },
                        [_c("img", { attrs: { src: _vm.baseImgUrl + item } })]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "subtitle",
                  attrs: { slot: "btn", text: "" },
                  on: { click: _vm.closeDialog },
                  slot: "btn"
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { slot: "btn", text: "" },
                  on: { click: _vm.setUserAvatar },
                  slot: "btn"
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "userInfo card1" },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "userInfo__avatar pb-2",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm.isLiffMode
                            ? [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "mt-2",
                                    style: _vm.avatarBorderClr,
                                    attrs: { size: "83" }
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: _vm.lineAvatar }
                                    })
                                  ]
                                )
                              ]
                            : [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "mt-2",
                                    style: _vm.avatarBorderClr,
                                    attrs: { size: "83" },
                                    on: { click: _vm.preChangeUserAvatar }
                                  },
                                  [
                                    _vm.userBaseInfo.avatar
                                      ? _c("img", {
                                          attrs: {
                                            src:
                                              _vm.baseImgUrl +
                                              _vm.userBaseInfo.avatar,
                                            alt: "userBaseInfo.username"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ]
                        ],
                        2
                      ),
                      !_vm.isLogin
                        ? [
                            _c(
                              "v-col",
                              { staticClass: "pt-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mb-4",
                                    attrs: {
                                      to: "/login",
                                      outlined: "",
                                      color: "primary"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "subtitle-2 primary--text"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("flashMessage.loginFirst")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "userInfo__name pt-0 pb-3 subtitle-2",
                                attrs: { cols: "12" }
                              },
                              [
                                _vm.isLiffMode
                                  ? [
                                      _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(_vm._s(_vm.lineDisplayName))
                                      ]),
                                      _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.userData.member.account
                                            ) +
                                            ")"
                                        )
                                      ])
                                    ]
                                  : [
                                      _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.userData.member.account)
                                        )
                                      ])
                                    ],
                                _vm.showVip
                                  ? _c("Icon", {
                                      attrs: {
                                        data: require("@icon/vip/diamond.svg"),
                                        width: "16px",
                                        height: "13px",
                                        color:
                                          "#01D0FB #DFF6FD #99E6FC #F2FBFF #DFF6FD #C8EFFE #99E6FC #62DBFB"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm.showVip
                              ? [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "userInfo__progress vip d-flex align-center py-0 px-10",
                                      attrs: { cols: "" }
                                    },
                                    [
                                      _vm.nowVipData.logo
                                        ? _c("v-img", {
                                            staticClass: "vip__now",
                                            attrs: {
                                              src:
                                                _vm.baseImgUrl +
                                                _vm.nowVipData.logo,
                                              "max-height": "28",
                                              "max-width": "28"
                                            }
                                          })
                                        : _vm._e(),
                                      _c("v-progress-linear", {
                                        staticClass: "mx-1 vip__progress",
                                        attrs: {
                                          rounded: "",
                                          value: _vm.vipProgress.progress,
                                          color: "primary",
                                          height: "9"
                                        }
                                      }),
                                      _vm.nextVipData.logo
                                        ? _c("v-img", {
                                            staticClass: "vip__next",
                                            attrs: {
                                              src:
                                                _vm.baseImgUrl +
                                                _vm.nextVipData.logo,
                                              "max-height": "28",
                                              "max-width": "28"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols:
                                          "12 text-left pb-4 subtitle-2 px-8 pt-0"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vip__description caption comment--text text-center"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("myCenter.myVipLevel") +
                                                  ": " +
                                                  (_vm.nowVipData.name
                                                    ? _vm.nowVipData.name
                                                    : "--")
                                              ) +
                                              " "
                                          ),
                                          _vm.nextVipData
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "myCenter.myVipCondition",
                                                        {
                                                          deposit:
                                                            _vm.upgradeNeedDeposit,
                                                          turnover:
                                                            _vm.upgradeNeedTurnover
                                                        }
                                                      ) +
                                                        " " +
                                                        _vm.nextVipData.name
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.style !== "darkGD"
            ? _c(
                "v-row",
                { staticClass: "card1 pb-2" },
                [_c("v-divider", { staticClass: "divider" })],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "walletArea card1" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _vm.style !== "darkGD"
                        ? [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-left primary--text py-0 d-flex align-center font-weight-bold pl-6 caption",
                                attrs: { cols: "4" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.mainWallet")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-right title--text py-0 pr-6",
                                attrs: { cols: "8" }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.currencySymbol_) + " "
                                  )
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "user-wallet ml-1" },
                                  [_vm._v(_vm._s(_vm.userWallet))]
                                )
                              ]
                            )
                          ]
                        : [
                            _c(
                              "v-col",
                              { staticClass: "px-4", attrs: { cols: "12" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between align-center dark-bg px-4 wallet-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "subtitle-2 d-flex align-center font-weight-bold"
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              width: "16",
                                              height: "16",
                                              viewBox: "0 0 16 16",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                "clip-rule": "evenodd",
                                                d:
                                                  "M0 8c0-4.412 3.588-8 8-8s8 3.588 8 8-3.588 8-8 8-8-3.588-8-8zm8.645-5.294c.987.137 1.686.631 2.276 1.248L9.906 5.106c-.562-.52-1.056-.809-1.755-.809-.81 0-1.262.384-1.262 1.138 0 .726.759 1.094 1.63 1.517 1.169.568 2.54 1.234 2.54 2.995 0 1.495-.837 2.606-2.414 2.908v1.59H7.287V12.91c-.933-.11-1.989-.535-2.716-1.193l.892-1.358c.754.59 1.481.932 2.263.932.96 0 1.399-.425 1.399-1.206 0-.825-.828-1.25-1.746-1.721-1.142-.586-2.424-1.243-2.424-2.833 0-1.494.892-2.523 2.332-2.797V1.143h1.358v1.563z",
                                                fill: "#fff"
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("myCenter.mainWallet")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "global." +
                                                  _vm.currencySymbol +
                                                  " "
                                              )
                                            )
                                          )
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "user-wallet ml-1" },
                                          [_vm._v(_vm._s(_vm.userWallet))]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                      _vm.showCard
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center d-flex justify-center align-center pa-0",
                              attrs: {
                                cols:
                                  (_vm.isLiffMode &&
                                    _vm.isMycardDomainService) ||
                                  _vm.withdrawalIsFlatten
                                    ? 4
                                    : 3
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0",
                                  attrs: { block: "", text: "", height: "56" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goPageFunc("user/card")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-center justify-center icon--text caption font-weight-bold"
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "mr-1",
                                        class: [
                                          _vm.style === "darkGD"
                                            ? "primary--text"
                                            : ""
                                        ],
                                        attrs: {
                                          data: require("@icon/user/walletItem/card.svg"),
                                          width: "23px",
                                          height: "20px"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.cards")) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.isLiffMode && !_vm.isMycardDomainService) ||
                      !_vm.isLiffMode
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center d-flex justify-center align-center pa-0",
                              attrs: { cols: _vm.withdrawalIsFlatten ? 4 : 3 }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0",
                                  attrs: {
                                    block: "",
                                    to: "user/withdrawal",
                                    text: "",
                                    height: "56"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-center justify-center icon--text caption font-weight-bold"
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "mr-1",
                                        class: [
                                          _vm.style === "darkGD"
                                            ? "primary--text"
                                            : ""
                                        ],
                                        attrs: {
                                          data: require("@icon/user/walletItem/withdrawal.svg"),
                                          width: "23px",
                                          height: "20px"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.withdraw")) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-center d-flex justify-center align-center pa-0",
                          attrs: {
                            cols:
                              (_vm.isLiffMode && _vm.isMycardDomainService) ||
                              _vm.withdrawalIsFlatten
                                ? 4
                                : 3
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-0",
                              attrs: {
                                block: "",
                                to: "user/transfer",
                                text: "",
                                height: "56"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-center icon--text caption font-weight-bold"
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "mr-1",
                                    class: [
                                      _vm.style === "darkGD"
                                        ? "primary--text"
                                        : ""
                                    ],
                                    attrs: {
                                      data: require("@icon/user/walletItem/transfer.svg"),
                                      width: "23px",
                                      height: "20px"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("global.transferBtn")) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-center d-flex justify-center align-center pa-0",
                          attrs: {
                            cols:
                              (_vm.isLiffMode && _vm.isMycardDomainService) ||
                              _vm.withdrawalIsFlatten
                                ? 4
                                : 3
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-0",
                              attrs: { block: "", text: "", height: "56" },
                              on: {
                                click: function($event) {
                                  return _vm.goPageFunc("user/deposit")
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-center icon--text caption font-weight-bold"
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "mr-1",
                                    class: [
                                      _vm.style === "darkGD"
                                        ? "primary--text"
                                        : ""
                                    ],
                                    attrs: {
                                      data: require("@icon/user/walletItem/deposit.svg"),
                                      width: "23px",
                                      height: "20px"
                                    }
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("global.deposit")) + " "
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-4 py-3" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              style:
                                _vm.style === "darkGD"
                                  ? {
                                      border:
                                        "1px solid " + _vm.primaryColor + " "
                                    }
                                  : "",
                              attrs: {
                                block: "",
                                height: "80",
                                color: "card1"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goPageFunc("user/trade")
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    " d-flex flex-column align-center justify-center icon--text"
                                },
                                [
                                  _c("Icon", {
                                    class: [
                                      _vm.style === "darkGD"
                                        ? "primary--text"
                                        : ""
                                    ],
                                    attrs: {
                                      data: require("@icon/user/mainControl/transfer.svg"),
                                      width: "48px",
                                      height: "48px"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("global.transactionRecord")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              style:
                                _vm.style === "darkGD"
                                  ? {
                                      border:
                                        "1px solid " + _vm.primaryColor + " "
                                    }
                                  : "",
                              attrs: {
                                to: "user/wager",
                                block: "",
                                height: "80",
                                color: "card1"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column align-center justify-center icon--text"
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "pa-2",
                                    class: [
                                      _vm.style === "darkGD"
                                        ? "primary--text"
                                        : ""
                                    ],
                                    attrs: {
                                      data: require("@icon/user/mainControl/wager.svg"),
                                      width: "48px",
                                      height: "48px"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.wagerRecord")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              style:
                                _vm.style === "darkGD"
                                  ? {
                                      border:
                                        "1px solid " + _vm.primaryColor + " "
                                    }
                                  : "",
                              attrs: {
                                to: "user/prize",
                                block: "",
                                height: "80",
                                color: "card1"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column align-center justify-center icon--text"
                                },
                                [
                                  _c("Icon", {
                                    class: [
                                      _vm.style === "darkGD"
                                        ? "primary--text"
                                        : ""
                                    ],
                                    attrs: {
                                      data: require("@icon/user/mainControl/gift.svg"),
                                      width: "48",
                                      height: "48"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.prizeRecord")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              style:
                                _vm.style === "darkGD"
                                  ? {
                                      border:
                                        "1px solid " + _vm.primaryColor + " "
                                    }
                                  : "",
                              attrs: {
                                to: "user/personal",
                                block: "",
                                height: "80",
                                color: "card1"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-center icon--text"
                                },
                                [
                                  _c("ProfileIcon", {
                                    staticClass: "ma-2",
                                    attrs: {
                                      width: "40px",
                                      height: "30px",
                                      color:
                                        _vm.style === "darkGD"
                                          ? _vm.primaryColor
                                          : _vm.profileClr
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "myCenter.personalInformation"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.showVip
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center px-1 py-1 ",
                              attrs: { cols: _vm.itemsWidth }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  style:
                                    _vm.style === "darkGD"
                                      ? {
                                          border:
                                            "1px solid " +
                                            _vm.primaryColor +
                                            " "
                                        }
                                      : "",
                                  attrs: {
                                    to: "user/vip",
                                    block: "",
                                    height: "80",
                                    color: "card1"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-100 d-flex flex-column align-center justify-center icon--text"
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "pa-2",
                                        class: [
                                          _vm.style === "darkGD"
                                            ? "primary--text"
                                            : ""
                                        ],
                                        attrs: {
                                          data: require("@icon/user/mainControl/vip.svg"),
                                          width: "48px",
                                          height: "48px"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("myCenter.myVip")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            {
                              staticClass: "text-center px-1 py-1 ",
                              attrs: { cols: _vm.itemsWidth }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  style:
                                    _vm.style === "darkGD"
                                      ? {
                                          border:
                                            "1px solid " +
                                            _vm.primaryColor +
                                            " "
                                        }
                                      : "",
                                  attrs: {
                                    to: "faq/problem",
                                    block: "",
                                    height: "80",
                                    color: "card1"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-100 d-flex flex-column align-center justify-center icon--text"
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "pa-2",
                                        class: [
                                          _vm.style === "darkGD"
                                            ? "primary--text"
                                            : ""
                                        ],
                                        attrs: {
                                          data: require("@icon/user/mainControl/help.svg"),
                                          width: "48px",
                                          height: "48px"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("myCenter.commonProblem")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              style:
                                _vm.style === "darkGD"
                                  ? {
                                      border:
                                        "1px solid " + _vm.primaryColor + " "
                                    }
                                  : "",
                              attrs: {
                                to: "user/news",
                                block: "",
                                height: "80",
                                color: "card1"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column align-center justify-center icon--text"
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "pa-2",
                                    class: [
                                      _vm.style === "darkGD"
                                        ? "primary--text"
                                        : ""
                                    ],
                                    attrs: {
                                      data: require("@icon/user/mainControl/msg.svg"),
                                      width: "48px",
                                      height: "48px"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("myCenter.news")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-1 px-4", attrs: { cols: "12" } },
                [
                  _vm._l(_vm.pageItem, function(item, index) {
                    return _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.noShowRouterLink.includes(
                              Object.keys(item)[0]
                            ),
                            expression:
                              "!noShowRouterLink.includes(Object.keys(item)[0])"
                          }
                        ],
                        key: index,
                        staticClass:
                          "text-decoration-none title--text pageLink",
                        attrs: { to: "/" + Object.keys(item)[0] }
                      },
                      [
                        _c("v-divider", { staticClass: "pb-3 divider" }),
                        _c(
                          "div",
                          { staticClass: "d-flex mb-2 px-2" },
                          [
                            _c(
                              "span",
                              { staticClass: "caption font-weight-medium" },
                              [
                                _vm._v(
                                  " " + _vm._s(Object.values(item)[0]) + " "
                                )
                              ]
                            ),
                            _c("v-spacer"),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v(" mdi-chevron-right ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "text-decoration-none title--text pageLink"
                    },
                    [
                      _c("v-divider", { staticClass: "pb-3 divider" }),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex mb-2 px-2",
                          on: {
                            click: function($event) {
                              _vm.language.status = !_vm.language.status
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "caption font-weight-medium" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("myCenter.language")) + " "
                              )
                            ]
                          ),
                          _c("v-spacer"),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v(" mdi-chevron-right ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isLogin
                    ? _c(
                        "div",
                        {
                          staticClass: "title--text pageLink",
                          on: { click: _vm.userLogout }
                        },
                        [
                          _c("v-divider", { staticClass: "pb-3 divider" }),
                          _c(
                            "div",
                            { staticClass: "d-flex mb-2 px-2" },
                            [
                              _c(
                                "span",
                                { staticClass: "caption font-weight-medium" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("subHeader.logout")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("v-spacer"),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v(" mdi-chevron-right ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-bottom-sheet",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.language.status,
                callback: function($$v) {
                  _vm.$set(_vm.language, "status", $$v)
                },
                expression: "language.status"
              }
            },
            [
              _c(
                "v-sheet",
                { staticClass: "text-center", attrs: { height: "200px" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto bg", attrs: { height: "100vh" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-toolbar",
                            { staticClass: "bg" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.language.status = !_vm.language.status
                                    }
                                  }
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "icon--text",
                                    attrs: {
                                      data: require("@icon/toolbar/arrow_left.svg"),
                                      width: "16px",
                                      height: "16px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-toolbar-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.language")) +
                                    " "
                                )
                              ]),
                              _c("v-spacer")
                            ],
                            1
                          ),
                          _c(
                            "v-list",
                            {
                              staticClass: "bg",
                              attrs: { "three-line": "", subheader: "" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "search-area" },
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      staticClass: "mx-4 mt-2 inputBg",
                                      attrs: {
                                        id: "searchInput",
                                        autofocus: "",
                                        "hide-details": "",
                                        placeholder: _vm.$t("game.searchGame"),
                                        rounded: "",
                                        autocomplete: "off",
                                        dense: ""
                                      },
                                      model: {
                                        value: _vm.language.searchKeyword,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.language,
                                            "searchKeyword",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "language.searchKeyword"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-0",
                                          attrs: { slot: "prepend-inner" },
                                          slot: "prepend-inner"
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { slot: "btn", icon: "" },
                                              slot: "btn"
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass: "white--text",
                                                attrs: {
                                                  data: require("@icon/search.svg"),
                                                  width: "24",
                                                  height: "24"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._l(_vm.language.resultLangs, function(lang) {
                            return _c(
                              "v-list",
                              {
                                key: lang._id,
                                staticClass: "bg text-left px-4 py-0"
                              },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    class: [
                                      {
                                        "primary rounded-xl":
                                          _vm.activeLang === lang.code
                                      }
                                    ],
                                    on: {
                                      click: function($event) {
                                        return _vm.settingClientLang(lang.code)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "local-name mr-3 title--text"
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: _vm.baseImgUrl + lang.icon,
                                            "max-width": "20"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "default-name title--text"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(lang.default_name) + " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c("v-divider", { staticClass: "divider" })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }